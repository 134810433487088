import React, { useEffect, useState } from 'react';
import { Table, Tag, Spin, Input, Button, Tooltip, message, Badge } from 'antd';
import axios from 'axios';
import { CheckOutlined, CopyOutlined } from '@ant-design/icons';

interface TokenDetail {
    TokenAddress: string;
    TokenName: string;
}

interface SuspectedInsiderDataType {
    New: boolean;
    NewMouseAddress: boolean;
    TxRelative: boolean;
    WalletAddress: string;
    FirstPurchaseTime: string;
    TokenDetails: TokenDetail[];
}

const SuspectedInsider: React.FC = () => {
    const [data, setData] = useState<SuspectedInsiderDataType[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const apiUrl = process.env.REACT_APP_API_URL;

    const [tokenName, setTokenName] = useState<string>('');
    const [tokenAddress, setTokenAddress] = useState<string>('');
    const [walletAddress, setWalletAddress] = useState<string>('');

    const fetchSuspectedData = async () => {
        setLoading(true);
        try {
            const response = await axios.post(`${apiUrl}/core/v1/getSuspectedInsiderData`, {
                tokenName, tokenAddress, walletAddress
            }, {
                headers: {
                    'Auth-Key': localStorage.getItem('authToken'),
                },
            });

            setData(response.data);
        } catch (error) {
            console.error('数据获取失败:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchSuspectedData();
    }, []);

    const formatAddress = (address: string | undefined) => {
        if (!address || address.length < 8) return address || '';
        return `${address.slice(0, 4)}...${address.slice(-4)}`;
    };

    const handleCopy = (address: string) => {
        navigator.clipboard.writeText(address)
            .then(() => message.success('地址复制成功!'))
            .catch(() => message.error('复制失败，请重试!'));
    };

    const columns = [
        {
            title: '钱包地址',
            dataIndex: 'WalletAddress',
            key: 'WalletAddress',
            render: (text: string) => (
                <span>
                    {formatAddress(text)}
                    {text && (
                        <Button
                            type="text"
                            icon={<CopyOutlined />}
                            size="small"
                            onClick={() => handleCopy(text)}
                            style={{ marginLeft: 8 }}
                        />
                    )}
                </span>
            ),
        },
        {
            title: '代币详情',
            dataIndex: 'TokenDetails',
            key: 'TokenDetails',
            render: (tokenDetails: TokenDetail[] = []) => (
                <div>
                    {tokenDetails.map((token, index) => (
                        <Tag color="green" key={index}>
                            <Tooltip title={token.TokenAddress}>
                                <span>
                                    {token.TokenName}
                                    <Button
                                        type="text"
                                        icon={<CopyOutlined />}
                                        size="small"
                                        onClick={() => handleCopy(token.TokenAddress)}
                                        style={{ marginLeft: 4 }}
                                    />
                                </span>
                            </Tooltip>
                        </Tag>
                    ))}
                </div>
            ),
        },
        {
            title: '最早买入时间',
            dataIndex: 'FirstPurchaseTime',
            key: 'FirstPurchaseTime',
        },
        {
            title: '新地址',
            dataIndex: 'New',
            key: 'New',
            render: (isNew: boolean) =>
                isNew ? (
                    <Badge count={<CheckOutlined style={{ color: 'white', fontWeight: 'bold' }} />}
                        style={{ backgroundColor: 'blue', borderRadius: '8px', padding: '6px' }} />
                ) : null,
        },
        {
            title: '老鼠仓',
            dataIndex: 'NewMouseAddress',
            key: 'NewMouseAddress',
            render: (newMouseAddress: boolean) =>
                newMouseAddress ? (
                    <Badge count={<CheckOutlined style={{ color: 'white', fontWeight: 'bold' }} />}
                        style={{ backgroundColor: 'orange', borderRadius: '8px', padding: '6px' }} />
                ) : null,
        },
        {
            title: '转账关联',
            dataIndex: 'TxRelative',
            key: 'TxRelative',
            render: (txRelative: boolean) =>
                txRelative ? (
                    <Badge count={<CheckOutlined style={{ color: 'white', fontWeight: 'bold' }} />}
                        style={{ backgroundColor: 'purple', borderRadius: '8px', padding: '6px' }} />
                ) : null,
        },
    ];

    return (
        <div>
            <h1>疑似内幕列表</h1>
            <Input
                placeholder="代币名称"
                value={tokenName}
                onChange={(e) => setTokenName(e.target.value)}
                style={{ width: 200, marginRight: 10 }}
            />
            <Input
                placeholder="代币地址"
                value={tokenAddress}
                onChange={(e) => setTokenAddress(e.target.value)}
                style={{ width: 200, marginRight: 10 }}
            />
            <Input
                placeholder="钱包地址"
                value={walletAddress}
                onChange={(e) => setWalletAddress(e.target.value)}
                style={{ width: 200, marginRight: 10 }}
            />
            <Button type="primary" onClick={fetchSuspectedData}>搜索</Button>
            {loading ? <Spin tip="Loading data..." /> : (
                <Table columns={columns} dataSource={data} className="custom-table" rowKey="WalletAddress" pagination={{ pageSize: 100 }} />
            )}
        </div>
    );
};

export default SuspectedInsider;