import React, { useState, useEffect } from 'react';
import { Table, Button, message, Badge } from 'antd';
import axios from 'axios';
import { CopyOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import './TableComponent.css';

interface BlacklistEntry {
    id: number;
    target_address: string;
    create_time: string;
    ignore_new: boolean;
    ignore_tx: boolean;
    ignore_mouse: boolean;
    ignore_sol_source: boolean;
    ignore_spl_source: boolean;
    remark: string;
}

const BlacklistPage: React.FC = () => {
    const [blacklistData, setBlacklistData] = useState<BlacklistEntry[]>([]);
    const [loading, setLoading] = useState(false);

    // 获取黑名单数据
    const fetchBlacklist = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/core/v1/getBlackList`, {
                headers: {
                    'Auth-Key': localStorage.getItem('authToken'),
                },
            });
            setBlacklistData(response.data);
        } catch (error) {
            message.error('获取黑名单列表失败');
            console.error('Fetch error:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchBlacklist(); // 页面加载时请求数据
    }, []);

    // 处理地址省略
    const formatAddress = (address: string) => {
        if (address.length >= 14) {
            return `${address.slice(0, 4)}...${address.slice(-4)}`;
        }
        return address; // 如果字符串长度小于8，则不进行省略
    };

    // 复制地址函数（带有手动复制的后备方案）
    const handleCopy = (address: string) => {
        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(address)
                .then(() => {
                    message.success('地址复制成功!');
                })
                .catch(() => {
                    message.error('复制失败，请重试!');
                });
        } else {
            // 创建一个隐藏的textarea元素来手动复制
            const textArea = document.createElement('textarea');
            textArea.value = address;
            document.body.appendChild(textArea);
            textArea.select();
            try {
                document.execCommand('copy');
                message.success('地址复制成功!');
            } catch (err) {
                message.error('复制失败，请手动复制!');
            }
            document.body.removeChild(textArea);
        }
    };


    // 定义表格列
    const columns = [
        {
            title: 'ID',
            dataIndex: 'ID',
            key: 'ID',
            width: 50,
        },
        {
            title: '目標地址',
            dataIndex: 'TargetAddress',
            key: 'TargetAddress',
            width: 80,
            render: (text: string) => (
                <span>
                    {formatAddress(text)} {/* 使用 formatAddress 方法简化显示 */}
                    {text && (
                        <Button
                            type="text"
                            icon={<CopyOutlined />}
                            size="small"
                            onClick={() => handleCopy(text)} // 复制完整的 Address
                            style={{ marginLeft: 8 }}
                        />
                    )}
                </span>
            ),
        },
        {
            title: '地址類型',
            dataIndex: 'BlackListType',
            key: 'BlackListType',
            width: 50,
            render: (text: string) => {
                return text === 'exchange' ? '交易所' : text === 'pool' ? '池子' : text;
            },
        },
        {
            title: '創建時間',
            dataIndex: 'CreateTime',
            key: 'CreateTime',
            width: 150,
            render: (text: string) => {
                const date = new Date(text);
                // 格式化为 "yyyy-MM-dd HH:mm:ss" 格式
                return date.toLocaleString('zh-CN', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: false,
                }).replace(/\//g, '-');
            },
        },
        {
            title: '忽略新地址',
            dataIndex: 'IgnoreNew',
            key: 'IgnoreNew',
            width: 120,
            render: (value: boolean) => (
                <Badge
                    count={
                        value ? (
                            <CheckOutlined style={{ color: 'white', fontWeight: 'bold', fontSize: '16px' }} />
                        ) : (
                            <CloseOutlined style={{ color: 'white', fontWeight: 'bold', fontSize: '16px' }} />
                        )
                    }
                    style={{
                        backgroundColor: value ? 'green' : 'red',
                        borderRadius: '8px',
                        padding: '6px',
                    }}
                />
            ),
        },
        {
            title: '忽略轉賬關聯',
            dataIndex: 'IgnoreTx',
            key: 'IgnoreTx',
            width: 120,
            render: (value: boolean) => (
                <Badge
                    count={
                        value ? (
                            <CheckOutlined style={{ color: 'white', fontWeight: 'bold', fontSize: '16px' }} />
                        ) : (
                            <CloseOutlined style={{ color: 'white', fontWeight: 'bold', fontSize: '16px' }} />
                        )
                    }
                    style={{
                        backgroundColor: value ? 'green' : 'red',
                        borderRadius: '8px',
                        padding: '6px',
                    }}
                />
            ),
        },
        {
            title: '忽略老鼠倉',
            dataIndex: 'IgnoreMouse',
            key: 'IgnoreMouse',
            width: 120,
            render: (value: boolean) => (
                <Badge
                    count={
                        value ? (
                            <CheckOutlined style={{ color: 'white', fontWeight: 'bold', fontSize: '16px' }} />
                        ) : (
                            <CloseOutlined style={{ color: 'white', fontWeight: 'bold', fontSize: '16px' }} />
                        )
                    }
                    style={{
                        backgroundColor: value ? 'green' : 'red',
                        borderRadius: '8px',
                        padding: '6px',
                    }}
                />
            ),
        },
        {
            title: '忽略SOL來源',
            dataIndex: 'IgnoreSolSource',
            key: 'IgnoreSolSource',
            width: 120,
            render: (value: boolean) => (
                <Badge
                    count={
                        value ? (
                            <CheckOutlined style={{ color: 'white', fontWeight: 'bold', fontSize: '16px' }} />
                        ) : (
                            <CloseOutlined style={{ color: 'white', fontWeight: 'bold', fontSize: '16px' }} />
                        )
                    }
                    style={{
                        backgroundColor: value ? 'green' : 'red',
                        borderRadius: '8px',
                        padding: '6px',
                    }}
                />
            ),
        },
        {
            title: '忽略SPL來源',
            dataIndex: 'IgnoreSplSource',
            key: 'IgnoreSplSource',
            width: 120,
            render: (value: boolean) => (
                <Badge
                    count={
                        value ? (
                            <CheckOutlined style={{ color: 'white', fontWeight: 'bold', fontSize: '16px' }} />
                        ) : (
                            <CloseOutlined style={{ color: 'white', fontWeight: 'bold', fontSize: '16px' }} />
                        )
                    }
                    style={{
                        backgroundColor: value ? 'green' : 'red',
                        borderRadius: '8px',
                        padding: '6px',
                    }}
                />
            ),
        },
        {
            title: '備註',
            dataIndex: 'Remark',
            key: 'Remark',
            width: 150,
        },
    ];

    return (
        <div>
            <h1>黑名單列錶</h1>
            <Table
                columns={columns}
                dataSource={blacklistData}
                rowKey="id"
                loading={loading}
                className="custom-table"
                pagination={{ pageSize: 100 }}
            />
        </div>
    );
};

export default BlacklistPage;