import React, { useState, useEffect } from 'react';
import { Table, Button, message } from 'antd';
import axios from 'axios';
import { CopyOutlined } from '@ant-design/icons';
import './TableComponent.css';

interface ScheduledTask {
    id: number;
    token_address: string;
    start_time: { $date: string };
    end_time: { $date: string };
    is_active: boolean;
}

const ActiveScheduledTask: React.FC = () => {
    const [data, setData] = useState<ScheduledTask[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const apiUrl = process.env.REACT_APP_API_URL; // 替换为你的实际 API 地址

    useEffect(() => {
        fetchData();
    }, []);

    // 处理地址省略
    const formatAddress = (address: string) => {
        if (address.length >= 14) {
            return `${address.slice(0, 4)}...${address.slice(-4)}`;
        }
        return address; // 如果字符串长度小于8，则不进行省略
    };

    // 复制地址函数（带有手动复制的后备方案）
    const handleCopy = (address: string) => {
        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(address)
                .then(() => {
                    message.success('地址复制成功!');
                })
                .catch(() => {
                    message.error('复制失败，请重试!');
                });
        } else {
            // 创建一个隐藏的textarea元素来手动复制
            const textArea = document.createElement('textarea');
            textArea.value = address;
            document.body.appendChild(textArea);
            textArea.select();
            try {
                document.execCommand('copy');
                message.success('地址复制成功!');
            } catch (err) {
                message.error('复制失败，请手动复制!');
            }
            document.body.removeChild(textArea);
        }
    };

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${apiUrl}/core/v1/getActiveScheduledTask`, {
                headers: {
                    'Auth-Key': localStorage.getItem('authToken'),
                },
            });
            setData(response.data);  // 假设返回的数据是数组格式
        } catch (error) {
            message.error('获取定时任务列表失败');
            console.error('Fetch error:', error);
        } finally {
            setLoading(false);
        }
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'ID',
            key: 'ID',
        },
        {
            title: '代幣地址',
            dataIndex: 'TokenAddress',
            key: 'TokenAddress',
            render: (text: string) => (
                <span>
                    {formatAddress(text)} {/* 使用 formatAddress 方法简化显示 */}
                    {text && (
                        <Button
                            type="text"
                            icon={<CopyOutlined />}
                            size="small"
                            onClick={() => handleCopy(text)} // 复制完整的 Address
                            style={{ marginLeft: 8 }}
                        />
                    )}
                </span>
            ),
        },
        {
            title: '開始時間',
            dataIndex: 'StartTime',
            key: 'StartTime',
            render: (text: string) => convertUTCToBeijingTime(text),
        },
        {
            title: '結束時間',
            dataIndex: 'EndTime',
            key: 'EndTime',
            render: (text: string) => convertUTCToBeijingTime(text),
        },
        {
            title: '操作',
            dataIndex: 'IsActive',
            key: 'IsActive',
            render: (isActive: boolean) => (isActive ? 'Active' : 'Inactive'),
        },
    ];

    // UTC时间转换为北京时间
    const convertUTCToBeijingTime = (utcString: string) => {
        const date = new Date(utcString);
        if (isNaN(date.getTime())) return 'Invalid Date';

        // 北京时间 = UTC + 8 小时
        date.setHours(date.getHours() + 8);
        return date.toISOString().replace('T', ' ').split('.')[0]; // 格式化输出
    };

    return (
        <div>
            <h1>當前定時任務</h1>
            <Table
                columns={columns}
                dataSource={data}
                loading={loading}
                rowKey="id"
                className="custom-table"
                pagination={{ pageSize: 100 }}
            />
        </div>
    );
};

export default ActiveScheduledTask;