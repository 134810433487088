import React, { useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import TableComponent from './TableComponent';
import BlackList from './BlackList';
import ScheduledTask from './ScheduledTask';
import SuspectedInsider from './SuspectedInsider';


const App: React.FC = () => {
  const [loading, setLoading] = useState(false);

  return (
    <Router>
      <div style={{ padding: 20 }}>
        <h1>內部數據看闆</h1>

        {/* 导航链接 */}
        <nav>
          <Link to="/">主頁</Link> |
          <Link to="/black-list"> 黑名單列錶</Link> |
          <Link to="/suspected-insider"> 疑似內幕列錶</Link> |
          <Link to="/scheduled-task"> 定時任務列錶</Link>
        </nav>

        {/* 显示Loading的逻辑 */}
        {loading && <div className="loading-overlay">Loading...</div>}

        {/* 路由配置 */}
        <Routes>
          <Route path="/" element={<TableComponent setLoading={setLoading} />} />
          <Route path="/black-list" element={<BlackList />} />
          <Route path="/suspected-insider" element={<SuspectedInsider />} />
          <Route path="/scheduled-task" element={<ScheduledTask />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;